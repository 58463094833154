<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
  <CBox>
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
      <!-- <HeaderPremium />
      <Breadcrumb />
      <CStack
        display="flex"
        direction="row"
        :spacing="6"
        mt="20px"
        mb="50px"
        >
        <SidebarDashboard :currentUser="currentUser" />        -->
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox">
          <CBox mx="32px" my="20px">
            <CText fontWeight="bolder" fontSize="20px" color="fontMain">Web CV</CText>
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" />
          <CBox mx="32px" my="20px">
            <CBreadcrumb separator=">" color="breadcrumbNotActive2">
              <CBreadcrumbItem @click.native="$router.push({ name: 'Dashboard Web CV' })">
                <CBreadcrumbLink color="breadcrumbNotActive2" fontWeight="bold">Web CV</CBreadcrumbLink>
              </CBreadcrumbItem>
              <CBreadcrumbItem>
                <CBreadcrumbLink color="breadcrumbActive2" fontWeight="bold">Pilih Desain Web CV</CBreadcrumbLink>
              </CBreadcrumbItem>
            </CBreadcrumb>
            <ChooseWebCV :currentUser="currentUser" type="instant" @chooseDesign="chooseDesign" :disabledButton="disabledButton" />
            <!-- <CSimpleGrid :columns="3" mt="10px" :spacing="5">
              <CBox v-for="(item, index) in currentWebCVList" :key="index" style="position: relative;">
                <CImage :src="require(`@/assets/web-cv/web-cv-${item.slug}.png`)" objectFit="cover" objectPosition="top" h="250px" w="100%" />
                <CBox v-if="item.is_coming_soon" h="250px" w="100%" bg="comingSoon" style="transform: translateY(-100%); position: absolute;" align="center">
                  <CText d="table-cell" verticalAlign="middle" color="white" w="100%" h="inherit" fontWeight="bold">Coming Soon</CText>
                </CBox>
                <CBox align="center" mx="auto" my="10px">
                  <CText fontWeight="bold" align="center" mb="10px" color="fontMain">{{ item.name }}</CText>
                  <CBox v-if="!item.is_coming_soon">
                    <ButtonSecondary2 v-if="currentUser.web_cv_design_converted == item.slug" label="Dipilih" />
                    <ButtonBlue v-else label="Pilih" @click.native="chooseDesign(item.slug)" :disabled="disabledButton" />
                  </CBox>
                </CBox>
              </CBox>
            </CSimpleGrid> -->
          </CBox>
        </CBox>
      <!-- </CStack> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_USER_EMPLOYEE } from "@/store/auth.module"
// import SidebarDashboard from "@/components/sidebar/SidebarDashboard.vue"
// import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue"
// import HeaderPremium from "@/components/header/HeaderPremium.vue"
// import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary2 from "@/components/button/ButtonSecondary.vue"
import Swal from "sweetalert2";
import ChooseWebCV from "@/components/config-profile/CompChooseWebCV.vue";

export default {
  metaInfo: {
    title: "Pilih Desain",
    titleTemplate: "%s - Gethired.id"
  },
  props: {
    currentUser: {
      type: Object,
      default() {
        return {}
      },
    }
  },
  components: {
    // SidebarDashboard,
    // Breadcrumb,
    // HeaderPremium,
    // ButtonBlue,
    // ButtonSecondary2,
    ChooseWebCV,
  },
  data() {
    return {
      disabledButton: false
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    // currentUser() {
    //   const user = this.$store.getters.currentUser;
    //   return user;
    // },
    currentWebCVList() {
      const list =  this.$store.getters.currentWebCVList;
      const listUsed = [];
      for (const d of list) {
        if (this.currentUser.is_mentor && d.is_mentor) {
          listUsed.push(d);
        }
        if (!this.currentUser.is_mentor && !d.is_mentor) {
          listUsed.push(d);
        }
      }
      return listUsed;
    }
  },
  methods: {
    getData() {
      // setTimeout(async () => {
      this.$store.dispatch(GET_USER)
      // }, 100);
    },
    chooseDesign(slug) {
      this.disabledButton = true;

      this.$store
        .dispatch(UPDATE_USER_EMPLOYEE, {
          id: this.currentUser.emp_id,
          data: {
            web_cv_design: slug
          }
        })
        .then(() => {
          this.disabledButton = false;
          this.$router.push({ name: "Dashboard Web CV" })
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>
